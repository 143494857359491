import React, { useState } from "react";

export const FirstEngagementContext = React.createContext<any>(false);

const Store = ({ children }: any) => {
    const [isPageFirstEngaged, setIsPageFirstEngaged] = useState<any>(false);

    return (
        <FirstEngagementContext.Provider value={[isPageFirstEngaged, setIsPageFirstEngaged]}>
                {children}
        </FirstEngagementContext.Provider>
    );
}

export default Store;