import React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './header/Header';
import Loading from './loading/Loading';

const HomePage = React.lazy(() => import("./homePage/HomePage"));
const Game1 = React.lazy(() => import("./game1/Game1"));
const Game2 = React.lazy(() => import("./game2/Game2"));
const Game3 = React.lazy(() => import("./game3/Game3"));
const Game4 = React.lazy(() => import("./game4/Game4"));
const Game5 = React.lazy(() => import("./game5/Game5"));
const What = React.lazy(() => import("./what/What"));



function App() {
  return (
    <BrowserRouter>
      <div className='mainAppBody'>
        <div className="appHeaderPosition"><Header /></div>
          <Routes>
            <Route path="/" element={<React.Suspense fallback={<Loading />}>
              <HomePage />
            </React.Suspense>} />

            <Route path="/racer" element={<React.Suspense fallback={<Loading />}>
              <Game1 />
            </React.Suspense>} />

            <Route path="/2048" element={<React.Suspense fallback={<Loading />}>
              <Game2 />
            </React.Suspense>} />

            <Route path="/snake" element={<React.Suspense fallback={<Loading />}>
              <Game3 />
            </React.Suspense>} />

            <Route path="/tetris" element={<React.Suspense fallback={<Loading />}>
              <Game4 />
            </React.Suspense>} />

            <Route path="/memory" element={<React.Suspense fallback={<Loading />}>
              <Game5 />
            </React.Suspense>} />

            <Route path="/what" element={<React.Suspense fallback={<Loading />}>
              <What />
            </React.Suspense>} />
          </Routes>
        </div>
    </BrowserRouter>

  );
}

export default App;
