import { useNavigate } from 'react-router-dom';
import './Header.scss';

function Header() {

    const navigate = useNavigate();

    return (
        <div className="mainHeaderBody">
            <div className="textBox" onClick={() => navigate("/")}>
                <div className="title">Play and Play</div>
                <div className="description">Games & Music</div>
            </div>

            <div className="whatBox">
                <div onClick={() => navigate("/what")} >What?</div>
            </div>

        </div>
    );
}

export default Header;