import './Loading.scss';

function Loading() {
    return (
        <div className="loadingBody">
            &nbsp;
        </div>
    )

}

export default Loading;